<ng-container *ngFor="let semesterData of semesterModuleData">
  <div class="section-space">
    <span>{{semesterData.semester.abbreviation | semesterPipe}}</span>
    <app-v2semester-ects-status [semester]="semesterData.semester"></app-v2semester-ects-status>
 <span *ngIf="filterService.getShowFilter('Noten')" class="average-semster-grade">
      Semesterschnitt: {{calculateWeightedGrade(semesterData.semester.attempts, semesterData.semester.modules, roundGrade)}}
    </span>
  </div>
<div class="semester-modules" [style.grid-template-columns]="'repeat(' + gridSize + ', 33.5px)'">
    <ng-container *ngFor="let moduleData of semesterData.semesterModuleMap">
      <app-v2modul [ngClass]="calcModuleWidth(parseService.currentView.getValue(), moduleData.module)"
                   [currentAttempt]="moduleData.attempt"
                   [module]="moduleData.module"
                   [status]="calculateCurrentModuleStatus(moduleData.module.bezeichnung, semesterData.semester.abbreviation, student.attempts)"
                   [timeline]="true">
      </app-v2modul>
    </ng-container>
  </div>
</ng-container>
