<ng-container *ngIf="module">
  <div *ngIf="displayAbbreviation(module!) !== ''"
       class="module"
       [id]="!isInSidenav && module.bezeichnung"
       [title]="title ? title : module!.description"
       [style.width]="!timeline ? '100px' : ''"
       [style.height]="moduleHeight"
       [style.opacity]="highlightModule() ? '' : '0.2'"
       [ngClass]="[
        timeline ? 'timeline-module' : '',
        v2moduleService.getStatusClass(status),
               v2moduleService.displayDifficulty()

       ]">
    {{title ? title : displayAbbreviation(module!)}}
    <app-v2modul-attributes [attempt]="currentAttempt" [modul]="module"></app-v2modul-attributes>
    <div class="triangle" *ngIf="displayTriangle()"></div>
  </div>
<!--  //TODO click on module-->
</ng-container>
