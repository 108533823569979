import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {V2Student} from "../../../models/V2Student";

@Component({
  selector: 'app-v2profile-image',
  templateUrl: './v2profile-image.component.html',
  styleUrls: ['./v2profile-image.component.scss']
})
export class V2profileImageComponent implements OnChanges {

  @Input() student!: V2Student;
  imgUrl!: SafeResourceUrl | string;
  initials!: string;
  hideInfoModal = true;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  onImageClick(event: MouseEvent): void {
    this.hideInfoModal = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.student) {
      this.imgUrl = this.parseImageURL(this.student);
      this.initials = this.parseInitials(this.student);
    }
  }

  private parseImageURL(student: V2Student): SafeResourceUrl | string {
    if (!student.bild) {
      return '';
    }

    const image = student.bild!.replace(/^"(.+(?="$))"$/, '$1');
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${image}`);
  }

  private parseInitials(student: V2Student): string {
    return student.firstName?.substring(0,1)! + student.lastName.substring(0,1)!;
  }

}
