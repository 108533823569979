<div class="depending-modules-wrapper" *ngIf="v2module?.dependingModulesIDs.length! > 0">
  <div class="depending-modules-title display5 tertiary-color">
    <span>Empfohlene Vorkenntnisse</span>
    <span *ngIf="parseService.currentView.getValue() !== View.TREE" class="open-in-modul-tree-label"
          (click)="openInModuleTree()">Im Modulbaum öffnen</span>
  </div>
  <div class="depending-modules-container">
    <div class="parent-module-container">
      <app-modul [module]="module" [timeline]="false"></app-modul>
    </div>
    <div class="arrow-container mt-15">
      <app-arrow></app-arrow>
    </div>
    <div class="child-modules-container mt-15">
      <ng-container *ngFor="let mod of dependingV2Modules">
        <app-v2modul [module]="mod"
                     [timeline]="false"
                     [allAttempts]="moduleAttemptMap.get(mod)"
                     [currentAttempt]="moduleAttemptMap.get(mod)[0]"
                     [status]="calculateCurrentModuleStatus(
                      mod.bezeichnung,
                      moduleAttemptMap.get(mod)[0].semesterAbbreviation,
                      student.attempts)">
        </app-v2modul>
      </ng-container>
    </div>
  </div>
</div>
