import {V2AttemptStatus} from "./models/V2AttemptStatus";
import {V2Attempt} from "./models/V2Attempt";
import {V2ModuleStatus} from "./models/V2ModuleStatus";
import {V2Module} from "./models/V2Module";
import {formatNumber} from "@angular/common";

export function convertToAttemptStatus(status: string): V2AttemptStatus {
  switch (status) {
    case 'PASSED':
      return V2AttemptStatus.PASSED;
    case 'RUNNING':
      return V2AttemptStatus.RUNNING;
    case 'FAILED':
      return V2AttemptStatus.FAILED;
    case 'CREDITED':
      return V2AttemptStatus.CREDITED;
    default:
      return V2AttemptStatus.UNKNOWN;
  }
}

export function calculateCurrentModuleStatus(moduleAbbr: string, semesterAbbr: string, allAttempts: V2Attempt[]): V2ModuleStatus {
  const attempts = allAttempts.filter(a => a.semesterAbbreviation == semesterAbbr && a.moduleAbbreviation === moduleAbbr);

  if (attempts.length === 0) {
    return V2ModuleStatus.UNKNOWN;
  }

  let failedCount = 0;
  let passed = false;

  for (const attempt of attempts) {
    if (attempt.status === V2AttemptStatus.FAILED) {
      failedCount += 1;
    } else if (attempt.status === V2AttemptStatus.PASSED || attempt.status === V2AttemptStatus.CREDITED) {
      passed = true;
    }
  }

  if (failedCount >= 2) {
    return V2ModuleStatus.LOCKED;
  } else if (passed) {
    return V2ModuleStatus.PASSED;
  } else if (failedCount > 0) {
    return V2ModuleStatus.FAILED;
  } else {
    return V2ModuleStatus.RUNNING;
  }
}

export function calculateWeightedGrade(attempts: V2Attempt[], modules: V2Module[], roundingF: (grade: number) => string): string {
  if (!attempts || attempts.length === 0) return '-.-';

  const grades = attempts.map(attempt => {
    const module = modules.find(m => m.bezeichnung === attempt.moduleAbbreviation);
    if (module && !isNaN(Number(attempt.finalGrade))) {
      return new SuccessfulAttempt(attempt.finalGrade, module.ects);
    }
    return null;
  }).filter(sa => sa !== null && sa.grade >= 3.75);

  const gradeSum = grades.reduce((sum, curr) => sum + curr.grade * curr.credits, 0);
  const ects = grades.reduce((sum, curr) => sum + curr.credits, 0);

  const average = (gradeSum / ects);

  return isNaN(Number(average)) ? '-.-' : roundingF(average);
}

export function roundGrade(grade: number): string {
  return formatNumber(Number(grade), 'en-US', '1.1-2');
}

export function roundGradeToTenth(grade: number): string {
  return (Math.round(grade * 10) / 10).toFixed(1);
}

class SuccessfulAttempt {
  grade: number;
  credits: number;

  constructor(grade: number, credits: number) {
    this.grade = grade;
    this.credits = credits;
  }
}
