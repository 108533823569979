import {Injectable} from "@angular/core";
import {Modulteppich} from "../../models/Modulteppich";
import {Studiengaenge} from "../../models/Studiengaenge";
import {V2SjgAnmeldung} from "../models/V2SjgAnmeldung";
import {IStudienjahrgang} from "../../models/Studienjahrgang";
import {V2parseService} from "./v2parse.service";
import {ignorableSJStatuses} from "../models/V2SjgAnmeldung";
import {V2Student} from "../models/V2Student";
import {V2AttemptStatus} from "../models/V2AttemptStatus";

@Injectable({
  providedIn: 'root'
  })
export class v2AdminService {
  public static readonly AVAILABLE_STUDIENGAENGE: Array<Modulteppich> = Studiengaenge.availableModulteppiche;

  constructor() {}

  public matchStudentsWithStudienjahrgangInfo(students: Array<V2Student>, studienjahrgangInfos: Array<IStudienjahrgang>): V2Student[] {
    students.forEach(student => {
      const actives = this.removeStudienjahrgangAnmeldungenWithIgnorableStatus(student.sjgAnmeldungen || []);
      const inActives = this.removeStudienjahrgangAnmeldungenWithNonIgnorableStatus(student.sjgAnmeldungen || []);

      const studienjahrgangAnmeldungen = actives.concat(inActives);

      const matched = studienjahrgangAnmeldungen.map(sja => {
        const info = studienjahrgangInfos.find(info => info.studienjahrgangId === sja.sjgId);
        return { anmeldung: sja, info: V2parseService.parseStudyMode(info) };
      }).filter(m => m.info  && !m.info.nummer.substring(0, 5).toLowerCase().includes('m'));

      const matchedStudienjahrgang = matched[0] || { anmeldung: null, info: null};
      student.studienjahrgangInfo = matchedStudienjahrgang.info;
      student.modulteppichIsPublic = this.checkIfModulteppichIsPublic(matchedStudienjahrgang.info);
      student.modulteppichIsComplete = this.checkifModulteppichIsAvailable(matchedStudienjahrgang.info);
    });

    return students.filter(s => s.studienjahrgangInfo);
  }

  removeStudienjahrgangAnmeldungenWithIgnorableStatus(v2SjgAnmeldungen: Array<V2SjgAnmeldung>): Array<V2SjgAnmeldung> {
    return v2SjgAnmeldungen
      .filter(sja => !ignorableSJStatuses.includes(sja.statusName))
      .sort((a, b) => a.anmeldungsDatum.localeCompare(b.anmeldungsDatum) ? -1 : 1);
  }

  private removeStudienjahrgangAnmeldungenWithNonIgnorableStatus(v2SjgAnmeldungen: Array<V2SjgAnmeldung>): Array<V2SjgAnmeldung> {
    return v2SjgAnmeldungen
      .filter(sja => ignorableSJStatuses.includes(sja.statusName))
      .sort((a, b) => a.anmeldungsDatum.localeCompare(b.anmeldungsDatum) ? 1 : -1);
  }

  private checkIfModulteppichIsPublic(info: IStudienjahrgang): boolean {
    if (!info) {
      return false;
    }

    const matchedModulteppich = v2AdminService.AVAILABLE_STUDIENGAENGE
      .find(m => m.getStudiengangIds().includes(info.studiengangId!));
    return matchedModulteppich ? matchedModulteppich.getIsComplete() : false;
  }

  private checkifModulteppichIsAvailable(info: IStudienjahrgang): boolean {
    if (!info) {
      return false;
    }

    const matchedModulteppich = v2AdminService.AVAILABLE_STUDIENGAENGE
      .find(m => m.getStudiengangIds().includes(info.studiengangId!));
    return matchedModulteppich ? matchedModulteppich.getIsComplete() : false;
  }

  public calculateTotalFailedEctsStudents(studierendeAnmeldungen: V2Student[]): V2Student[] {
    const students: V2Student[] = [];
    studierendeAnmeldungen.forEach(student => {
      const critcalAnmeldungen = student.attempts.filter(a =>
      a.status === V2AttemptStatus.FAILED && a.finalGrade !== 0.0);

      let totalEcts = critcalAnmeldungen.reduce((total, anmeldung) => {
        const modul = student.modules.find(m => m.bezeichnung === anmeldung.moduleAbbreviation);
        return modul ? total + modul.ects : total;
      }, 0);
      const clonedStudent = JSON.parse(JSON.stringify(student));
      clonedStudent.totalFailedEcts = totalEcts;
      students.push(clonedStudent);
    })

    return students;
  }

  public filterCriticalStudents(studierendeAnmeldungen: V2Student[], maxFailedEcts: number): V2Student[] {
    const criticalStudents = [];
    studierendeAnmeldungen.forEach(student => {
      if (student.totalFailedEcts >= maxFailedEcts) {
        const clonedStudent = JSON.parse(JSON.stringify(student));
        criticalStudents.push(clonedStudent);
      }
    })

    return criticalStudents;
  }

  public calculateActiveStudents(students: V2Student[]): V2Student[] {
    return students.filter(s =>
      s.sjgAnmeldungen.some(a =>
        !ignorableSJStatuses.includes(a.statusName))
    );
  }

  public calculateStudentsWithRunningAssessment(students: V2Student[], sgid: number): V2Student[] {
    students = this.calculateStudentsAssessment(students, sgid);
    return students.filter(s => s.assessmentEcts < 27)
  }

  public calculateStudentsWithPassedAssessment(students: V2Student[],sgId: number): V2Student[] {
    students = this.calculateStudentsAssessment(students, sgId);
    return students.filter(s => s.assessmentEcts >= 27)
  }

  public calculateStudentsAssessment(students: V2Student[], sgId: number): V2Student[] {
    const studentsWithCalculatedAssessment: V2Student[] = [];

    const studiengang = V2parseService.AVAILABLE_STUDIENGAENGE.find(modulteppich => modulteppich.getStudiengangIds().includes(sgId));
    const assessments = studiengang?.getAssessment() as Array<string> || [];

    students.filter(student => {
      const creditedModulIds = student.attempts
        .filter(a => a.status === V2AttemptStatus.CREDITED)
        .map(a => a.id);

      const creditedModules = student.modules.filter(m =>
        student.attempts.some(a =>
          creditedModulIds.includes(a.id) &&
          assessments.includes(a.moduleAbbreviation) &&
          m.bezeichnung === a.moduleAbbreviation
        )
      );

      const passedAssessmentIds = student.attempts
        .filter(a => a.status === V2AttemptStatus.PASSED && a.finalGrade !== 0.0 && assessments.some(assessment => a.moduleAbbreviation === assessment))
        .map(a => a.id);

      const passedAssessmentModules = student.modules.filter(m =>
        student.attempts.some(a =>
        passedAssessmentIds.includes(a.id) &&
        assessments.includes(a.moduleAbbreviation) &&
          m.bezeichnung === a.moduleAbbreviation
        ));

      const assessmentEcts = [...creditedModules, ...passedAssessmentModules].reduce((totalEcts, modul) => totalEcts + (modul?.ects ?? 0), 0);
      const clonedStudent = JSON.parse(JSON.stringify(student));
      clonedStudent.assessmentEcts = assessmentEcts;
      studentsWithCalculatedAssessment.push(clonedStudent);
    });

    return studentsWithCalculatedAssessment;
  }

}
