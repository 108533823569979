import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {V2Student} from '../models/V2Student'
import {environment} from "../../../environments/environment";
import {convertToAttemptStatus} from "../v2utils";
import {map} from "rxjs/internal/operators";
import {Studienjahrgang} from "../../models/Studienjahrgang";

@Injectable({
  providedIn: 'root'
})
export class v2ApiService {

  private BaseURL = (window as any).Cypress ? 'http://localhost:3000/' :environment.apiUrlV2;

  constructor(
    private http: HttpClient
  ) { }

  fetchStudent(): Observable<V2Student> {
    const url = `${this.BaseURL}students/me`;
    return this.http.get<V2Student>(url).pipe(
      map(student => {
        student.attempts = student.attempts.map(attempt => ({
          ...attempt,
          status: convertToAttemptStatus(attempt.status.toString())
        }));
        return student;
      })

    );
  }

  fetchStudents(vorname?: string, nachname?: string, studienjahrgangId?: string): Observable<V2Student[]> {
    const url = `${this.BaseURL}admin/students/filter`;
    let params = new HttpParams();
    if (vorname) {
      params = params.set('vorname', vorname);
    }
    if (nachname) {
      params = params.set('nachname', nachname);
    }
    if (studienjahrgangId) {
      params = params.set('studienjahrgangId', studienjahrgangId);
    }
    return this.http.get<V2Student[]>(url, { params });
  }

  public fetchStudienJahrgaengeInfo(id?: string, studienjahrgangIds?: Array<string>, studiengangId?: string, all?: boolean, studienjahrgangId?: string): Observable<[Studienjahrgang]> {
    if (id) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang?id=${id}`);
    } else if (studienjahrgangIds) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang/infos?ids=${studienjahrgangIds}`);
    } else if (studiengangId) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang/infos?studiengangId=${studiengangId}`);
    } else if (all) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang/infos?all=true`);
    } else if (studienjahrgangId) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang/infos?studienjahrgangId=${studienjahrgangId}`);
    } else {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang/infos?all=true`);
    }
  }

}
