<img *ngIf="imgUrl" alt="profilbild" class="thumbnail" [src]="imgUrl" (click)="onImageClick($event)">
<div *ngIf="!imgUrl" class="no-thumbnail">{{initials}}</div>
<app-modal-profile-image *ngIf="imgUrl"
                         [hidden]="hideInfoModal"
                         [contentTemplate]="imageTemplate"
                         (hiddenChange)="hideInfoModal = $event">
</app-modal-profile-image>
<ng-template #imageTemplate>
  <img [src]="imgUrl" alt="profilbild" class="image-full-size">
</ng-template>
