<div
  [ngClass]="{
    'image-hidden': hidden,
    'image-show': !hidden
  }"
  class="modal-content"
  (click)="hide($event)">
  <div class="modal-container-wrapper">
      <div *ngIf="contentTemplate" class="modal-body-container">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
  </div>
</div>
<div [ngClass]="hidden ? 'background-hidden' : 'background-show'" class="modal-background"></div>
