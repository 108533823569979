import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul} from '../../../models/Modul';
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: 'app-modulteppich-energie-und-umwelttechnik2024',
  templateUrl: './modulteppich-energie-und-umwelttechnik2024.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichEnergieUndUmwelttechnik2024Component extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];

  public showWeitereVertiefungen = true;
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    profilierungService: ProfilierungService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService, profilierungService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
    const vert = this.parseService.getFlattenedModuleTree().filter(m => m.bezeichnung?.toLowerCase()?.includes('fachvert')); // Filters all Vertiefungen
    const wvert = vert[0].modules!.filter(m => m.bezeichnung?.toLowerCase().includes('weitere vert')); // Filter the "Weitere Vertiefungen"
    const otherVert = vert[0].modules!.filter(m => !m.bezeichnung?.toLowerCase().includes('weitere vert')); // Filters all the other Modulgroups that aren't "Weitere Vertiefungen"
    const filteredVert = wvert[0].modules!.filter(m => otherVert.some(om => !om.modules?.some(ovm => ovm.modulId === m.modulId))); // Removes double modules from filteredVert
    this.showWeitereVertiefungen = filteredVert.length > 0;
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }
}
