import {Component, EventEmitter, Input, Output} from '@angular/core';
@Component({
  selector: 'app-modal-profile-image',
  templateUrl: './modal-profile-image.component.html',
  styleUrls: ['./modal-profile-image.component.scss']
})
export class ModalProfileImageComponent{
  @Input() hidden = true;
  @Input() contentTemplate?: any;
  @Output() hiddenChange = new EventEmitter<boolean>();

  constructor() {}

  hide(event: Event): void {
    this.hidden = true;
    this.hiddenChange.emit(this.hidden);
    event.stopPropagation();
  }
}
