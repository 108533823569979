import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {ThemeService} from "../../../../services/theme.service";
import {Router} from "@angular/router";
import {ParseService} from "../../../../services/parse.service";
import {V2Student} from "../../../models/V2Student";
import {getCurrentSJGA} from "../v2admin-util";

@Component({
  selector: 'app-v2student-list',
  templateUrl: './v2student-list.component.html',
  styleUrls: ['./v2student-list.component.scss']
})
export class V2studentListComponent {

  @Input() loading: boolean = true;
  @Input() hasError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() studentsWithAnmeldungen: Array<V2Student> = [];

  @Input() filterChanged: any;
  @Input() filtersDisabled: boolean = true;

  studentFilterValue: string = '';

  @ViewChild('studentFilterInput') studentFilterInput: ElementRef;

  attribute: string = '';

  private isSortAscending: boolean = true;

  constructor(
    public themeService: ThemeService,
    private router: Router,
    public parseService: ParseService) {
  }

  public studentIsInFilterSearch(student: V2Student): boolean {
    const { firstName, lastName, email, id } = student;
    const studentWithSjgInfo = this.studentsWithAnmeldungen.find(s => s.id === id);
    const sjgDetails = studentWithSjgInfo?.studienjahrgangInfo?.bezeichnung;
    const status = studentWithSjgInfo?.studienjahrgangInfo?.statusName;
    const filterTerm = this.studentFilterValue.toLowerCase();

    return [firstName, lastName, email, sjgDetails, status, id]
      .filter(a => a!== undefined)
      .map(a => typeof a === 'string' ? a.toLowerCase() : `${a}`)
      .some(a => a.includes(filterTerm));
  }

  filteredStudentsWithStudienjahrgangInfo(): Array<V2Student> {
    return this.studentsWithAnmeldungen.filter(studisWithSJAInf =>
    this.studentIsInFilterSearch(studisWithSJAInf));
  }

  public selectStudent( student: V2Student, event?: any,): void {
    if (event){
      const targetElement = event.target as HTMLElement;
      if (targetElement.tagName === 'IMG') {
        event.stopPropagation();
        return; // Ignore the click if clicked on image
      }
    }
    if (student) {
      const studienjahrgangId = student.studienjahrgangInfo?.studienjahrgangId;
      if (studienjahrgangId) {
        this.router.navigate([`/student/${student.id}/studienjahrgang/${studienjahrgangId}`]);
        this.parseService.setLoading(true);
      }
    }
  }

  public parseStudiengangAnmeldungStatus(student: V2Student): string {
    return getCurrentSJGA(student)?.statusName;
  }

  public toggleStudentAttributeSort(attribute: string): void {
    this.isSortAscending = !this.isSortAscending;
    this.isSortAscending ? this.sortStudentsByStudentAttributeAscending(attribute) : this.sortStudentsByStudentAttributeDescending(attribute);
  }

  private sortStudentsByStudentAttributeAscending(attribute: string): void {
    this.isSortAscending = true;
    this.attribute = attribute;
    this.sortStudentsByAttribute();
  }

  public sortStudentsByStudentAttributeDescending(attribute: string): void {
    this.attribute = attribute;
    this.isSortAscending = false;
    this.sortStudentsByAttribute();
  }

  private sortStudentsByAttribute(): void {
    const attribute = this.attribute;
    const ascending = this.isSortAscending;
    if (ascending) {
      this.studentsWithAnmeldungen.sort((a: any, b: any) => (a[attribute] ) > ( b[attribute]) ? 1 : -1);
    } else {
      this.studentsWithAnmeldungen.sort((a: any, b: any) => (a[attribute] ) < ( b[attribute]) ? 1 : -1);
    }
  }

  public emailStudentFilterValue(): void {
    this.studentFilterValue = (this.studentFilterInput.nativeElement.value);
  }
}
