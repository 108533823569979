<div [ngSwitch]="parseService.getStudiengang()">
  <app-no-match *ngIf="!parseService.canViewModulteppich()"></app-no-match>
  <ng-container *ngIf="parseService.canViewModulteppich()">
    <app-modulteppich-informatik-2013 *ngSwitchCase="studiengangEnum.I2013"></app-modulteppich-informatik-2013>
    <app-modulteppich-informatik-2016 *ngSwitchCase="studiengangEnum.I2016"></app-modulteppich-informatik-2016>
    <app-modulteppich-informatik-2018 *ngSwitchCase="studiengangEnum.I2018"></app-modulteppich-informatik-2018>
    <app-modulteppich-icompetence-2016 *ngSwitchCase="studiengangEnum.IC2016"></app-modulteppich-icompetence-2016>
    <app-modulteppich-icompetence-2018 *ngSwitchCase="studiengangEnum.IC2018"></app-modulteppich-icompetence-2018>
    <app-modulteppich-wirtschaftsingenieurwesen-2019
      *ngSwitchCase="studiengangEnum.WING2019"></app-modulteppich-wirtschaftsingenieurwesen-2019>
    <app-modulteppich-systemtechnik *ngSwitchCase="studiengangEnum.STE"></app-modulteppich-systemtechnik>
    <app-modulteppich-maschinenbau-2018 *ngSwitchCase="studiengangEnum.M18"></app-modulteppich-maschinenbau-2018>
    <app-modulteppich-maschinenbau-2020 *ngSwitchCase="studiengangEnum.M20"></app-modulteppich-maschinenbau-2020>
    <app-modulteppich-elektro-und-informationstechnik
      *ngSwitchCase="studiengangEnum.EIT"></app-modulteppich-elektro-und-informationstechnik>
    <app-modulteppich-energie-und-umwelttechnik
      *ngSwitchCase="studiengangEnum.EUT"></app-modulteppich-energie-und-umwelttechnik>
    <app-modulteppich-energie-und-umwelttechnik2024
      *ngSwitchCase="studiengangEnum.EUT24"></app-modulteppich-energie-und-umwelttechnik2024>
    <app-modulteppich-data-science *ngSwitchCase="studiengangEnum.DS"></app-modulteppich-data-science>
    <app-modulteppich-optometrie-2021 *ngSwitchCase="studiengangEnum.O21"></app-modulteppich-optometrie-2021>
    <app-modulteppich-optometrie-2022 style="display: flex; justify-content: center;" *ngSwitchCase="studiengangEnum.O22"></app-modulteppich-optometrie-2022>
  </ng-container>
</div>
