import {Injectable} from '@angular/core';
import {dark, light, Theme} from '../models/Theme';
import {LocalStorageService} from './local-storage.service';
import {OnDestroyComponent} from './on-destroy.component';
import {ThemeConfig} from '../config/ThemeConfig';
import {FilterService} from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends OnDestroyComponent {

  private themeChangeListeners: Array<() => void> = [];
  private systemColorSchemePreferenceListeners: Array<(e: MediaQueryListEvent) => void> = [];

  constructor(
    private localStorageService: LocalStorageService,
    private filterService: FilterService
  ) {
    super();
    this.handleDestroy(localStorageService.config).subscribe(config => {
      Object.entries(this.getActiveTheme(config.theme).properties).forEach((property) => {
        document.documentElement.style.setProperty(
          property[0], property[1]
        );
      });
      this.filterService.config = config;
    });

    if (this.filterService.config.systemDefaultTheme) {
      if (this.getActiveTheme(this.filterService.config.theme) === dark && !window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setActiveTheme(ThemeConfig.Light);
      } else if (this.getActiveTheme(this.filterService.config.theme) === light && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setActiveTheme(ThemeConfig.Dark);
      }
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      this.notifySystemColorSchemePreferenceListeners(e);
    });
    this.setupThemeUpdateNotification();
    this.addSystemColorSchemePreferenceListener(this.systemThemeSwitchListener);
  }

  private systemThemeSwitchListener = (e: MediaQueryListEvent) => {
    if (this.filterService.config.systemDefaultTheme) {
      if (e.matches) {
        this.setActiveTheme(ThemeConfig.Dark);
      } else {
        this.setActiveTheme(ThemeConfig.Light);
      }
    }
  }

  /**
   * Adds a listener to be notified when the color scheme changes.
   * @param listener The function to be added to the list of callbacks.
   */
  public addThemeChangedListener = (listener: () => void) => {
    this.themeChangeListeners.push(listener);
  }

  /**
   * Calls all callback functions in the list of listeners.
   */
  public notifyThemeChangedListeners = () => {
    this.themeChangeListeners.forEach((l: () => void) => l());
  }

  /**
   * Clears all callback functions.
   */
  public clearThemeChangedListeners(): void {
    this.themeChangeListeners = [];
  }

  /**
   * Calls the notification function if only the theme changed in the last config update.
   * @private
   */
  private setupThemeUpdateNotification(): void {
    let previousTheme = this.localStorageService.config.value.theme;
    this.localStorageService.config.subscribe(config => {
      if (config.theme !== previousTheme) {
        previousTheme = config.theme;
        this.notifyThemeChangedListeners();
      }
    });
  }

  isDark = () => this.filterService.config.theme === ThemeConfig.Dark;
  isSystemDefaultThemeChosen = () => this.filterService.config.systemDefaultTheme;
  systemRequestsDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

  getActiveTheme(theme: ThemeConfig): Theme {
    switch (theme) {
      case ThemeConfig.Dark:
        return dark;
      case ThemeConfig.Light:
        return light;
    }
  }

  setActiveTheme(theme: ThemeConfig): void {
    if (theme !== this.filterService.config.theme) {
      this.filterService.config.theme = theme;
      this.localStorageService.config.next(this.filterService.config);
    }
  }

  toggleUseSystemDefaultTheme(): void {
    if (this.filterService.config.systemDefaultTheme) {
      this.filterService.config.systemDefaultTheme = false;
      this.clearSystemColorSchemePreferenceListeners();
    } else {
      this.filterService.config.systemDefaultTheme = true;
      if (this.systemRequestsDark()) {
        this.setActiveTheme(ThemeConfig.Dark);
      } else {
        this.setActiveTheme(ThemeConfig.Light);
      }
      this.addSystemColorSchemePreferenceListener(this.systemThemeSwitchListener);
    }
  }

  toggleTheme(): void {
    if (this.filterService.config.theme === ThemeConfig.Dark) {
      this.setActiveTheme(ThemeConfig.Light);

      if (!this.systemRequestsDark() && !this.filterService.config.systemDefaultTheme) {
        this.filterService.config.systemDefaultTheme = true;
        this.addSystemColorSchemePreferenceListener(this.systemThemeSwitchListener);
      } else {
        this.filterService.config.systemDefaultTheme = false;
        this.clearSystemColorSchemePreferenceListeners();
      }
    } else {
      this.setActiveTheme(ThemeConfig.Dark);

      if (this.systemRequestsDark() && !this.filterService.config.systemDefaultTheme) {
        this.filterService.config.systemDefaultTheme = true;
        this.addSystemColorSchemePreferenceListener(this.systemThemeSwitchListener);
      } else {
        this.filterService.config.systemDefaultTheme = false;
        this.clearSystemColorSchemePreferenceListeners();
      }
    }
  }

  addSystemColorSchemePreferenceListener(l: (e: MediaQueryListEvent) => void): void {
    this.systemColorSchemePreferenceListeners.push(l);
  }

  private notifySystemColorSchemePreferenceListeners(e: MediaQueryListEvent): void {
    this.systemColorSchemePreferenceListeners.forEach(l => l(e));
  }

  clearSystemColorSchemePreferenceListeners(): void {
    this.systemColorSchemePreferenceListeners = [];
  }

  getSystemColorSchemePreferenceListenerCount(): number {
    return this.systemColorSchemePreferenceListeners.length;
  }

  /**
   * Toggles the winterTheme and saves it to the localStorage Config
   */
  toggleWinterTheme(): void {
    this.localStorageService.config.next({...this.filterService.config, winter: !this.filterService.config.winter});
  }

  /**
   * Checks the current Date if it is in winter or not
   */
  // TODO remove November
  isCurrentDateDuringWinter(): boolean {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    return month >= 10 || month <= 1;
  }

  /**
   * Checks the current Date if it is during the advent time
   */
  isCurrentDateDuringAdventTime(): boolean {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    return month === 11 && day >= 1 && day <= 26;
  }

  /**
   * Checks if the current Date is in winter and if the winterTheme is enabled in the config
   */
  displayWinterTheme(): boolean {
    return this.isCurrentDateDuringWinter() && this.filterService.config.winter;
  }

  /**
   * Checks if the current Date is in advent time and if the winterTheme is enabled in the config
   */
  displayAdventTheme(): boolean {
    return this.isCurrentDateDuringAdventTime() && this.filterService.config.winter;
  }
}


